import { Conversation, ProtectedRoute } from 'components';
import { ChatCostSummary, ChatList, EditChat, Login, UpdateChatForm } from 'pages/dashboard';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts';
import './index.css';
import NotFound from './NotFound';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, Box } from '@mui/material';
import { defaultThemeOptions } from 'utils';
import Conversations from 'pages/dashboard/Conversations';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={createTheme(defaultThemeOptions)}>
      <AuthProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/:id" element={<Conversation />} />
            <Route path="/dashboard">
              <Route path="login" element={<Login />} />
              <Route
                path="edit/:id"
                element={
                  <ProtectedRoute>
                    <EditChat />
                  </ProtectedRoute>
                }
              />
              <Route
                path="chats"
                element={
                  <ProtectedRoute>
                    <ChatList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="create"
                element={
                  <ProtectedRoute>
                    <Box p={4} maxWidth="md">
                      <UpdateChatForm />
                    </Box>
                  </ProtectedRoute>
                }
              />
              <Route
                path="cost-summary/:id"
                element={
                  <ProtectedRoute>
                    <ChatCostSummary />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":chatId/conversations"
                element={
                  <ProtectedRoute>
                    <Conversations />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/"
              Component={() => {
                window.location.href = 'https://sofi.coop/chatbot';
                return null;
              }}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
