import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isValidJSON(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export async function getRecaptchaToken() {
  if (!process.env.REACT_APP_RECAPTCHA_SITEKEY) {
    return Promise.resolve('');
  }
  return new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const recaptcha = (window as any).grecaptcha;
    if (!recaptcha) {
      setTimeout(() => {
        resolve(getRecaptchaToken());
      }, 100);
      return;
    }
    recaptcha.ready(() => {
      recaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITEKEY, {
          action: 'submit',
        })
        .then((token: string) => {
          resolve(token);
        });
    });
  });
}

export async function scrollToBottom() {
  if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
    return;
  }
  await sleep(50);
  window.scrollTo({
    behavior: 'smooth',
    top: document.body.scrollHeight,
  });
}

export async function fetchWithAuth(url: string, options: RequestInit = {}) {
  const token = localStorage.getItem('token');
  const headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };

  const response = await fetch(url, {
    ...options,
    headers,
  });

  return response;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
