import { Button } from 'components/shadcn/button';
import { Input } from 'components/shadcn/input';
import { Label } from 'components/shadcn/label';
import { AuthContext } from 'contexts';
import { apiUrl } from 'utils/constants';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchWithAuth } from 'utils';

interface IFormInputs {
  email: string;
  password: string;
}

const validationSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Required'),
  password: yup.string().required('Required'),
});

export const Login = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const { email, password } = data;
    try {
      const response = await fetch(`${apiUrl}/auth/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.token) {
        authContext?.login(data.token);
        const userResponse = await fetchWithAuth(`${apiUrl}/auth/user`);
        if (userResponse.ok) {
          const userResponseData = await userResponse.json();
          authContext?.saveUserId(userResponseData.id);
          authContext?.saveEmail(userResponseData.email);
          authContext?.saveRole(userResponseData.role);
        }
        navigate('/dashboard/chats');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px] lg:h-svh">
        <div className="flex items-center justify-center py-12">
          <div className="mx-auto grid w-[350px] gap-6">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold">Login</h1>
              <p className="text-balance text-muted-foreground">
                Enter your email below to login to your account
              </p>
            </div>
            <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <>
                        <Label htmlFor="email">Email</Label>
                        <Input
                          id="email"
                          type="email"
                          placeholder="you@sofi.coop"
                          required
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                        {error && (
                          <p className="text-[0.8rem] font-medium text-destructive">
                            {error.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="grid gap-2">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <>
                        <Label htmlFor="password">Password</Label>
                        <Input
                          id="password"
                          type="password"
                          required
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                        {error && (
                          <p className="text-[0.8rem] font-medium text-destructive">
                            {error.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
                <Button type="submit" className="w-full" variant="secondary">
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div
          className="hidden bg-muted lg:block"
          style={{
            backgroundImage: "url('/bg.png')",
            backgroundColor: '#05e292',
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        ></div>
      </div>
    </>
  );
};
