import { createContext, useState, ReactNode } from 'react';

interface IAuthContextType {
  isAuthenticated: boolean;
  userId: string;
  email: string;
  role: string;
  login: (token: string) => void;
  logout: () => void;
  saveEmail: (newEmail: string) => void;
  saveRole: (newRole: string) => void;
  saveUserId: (newUserId: string) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<IAuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return !!localStorage.getItem('token');
  });
  const [userId, setUserId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');

  const login = (token: string) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  const saveEmail = (newEmail: string) => {
    setEmail(newEmail);
  };

  const saveRole = (newRole: string) => {
    setRole(newRole);
  };

  const saveUserId = (newUserId: string) => {
    setUserId(newUserId);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        email,
        saveEmail,
        role,
        saveRole,
        userId,
        saveUserId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
